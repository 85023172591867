import React, { useState,useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../style/verify.css';
import axios from 'axios';
import localforage from 'localforage';
import lockImage from '../images/lock.png'
import PhoneInput from 'react-phone-input-2';


function Verify() {
  const navigate = useNavigate();
  const location = useLocation();

  const [code, setCode] = useState('');
  const [havingError,setHavingError] =  useState(false)
  const [havingSuccess,setHavingSuccess] =  useState(false)
  const [disbaled, setDisabled] = useState(false)
  const [disbaledChange, setDisabledChange] = useState(false)
  const [changeMobileView, setChangeMobileView] = useState(false)
  const [number,setMobile] = useState();

  const { mobile: initialMobile } = location.state || {};
  const [mobile, setMobileAfterChange] = useState(initialMobile || '');

  async function handleCodeSubmit()
  {
    setDisabled(true)
    const token = await localforage.getItem('token');
    const baseURL = process.env.REACT_APP_BASE_URL

    axios.post(`${baseURL}/api/customer/mobile/verification`, {
      token: token,
      code: code.replace(/-/g, '')
    })
    .then(function(response) {
        const data = response.data;
        if (data.status) {
          const values = data.data
          const propsToPass = { 
            name: values.name,
            date: values.date,
            time: values.time,
            email: values.email,
            phone: values.phone,
            image: values.image,
            legalName: values.legalName
          };
          navigate('/confirmation', { state: propsToPass });

        } else {
          setDisabled(false)
          setHavingError(data.message)
        }
    })
    .catch(function(error) {
        setDisabled(false)
        setHavingError('Error:', error);
       
    });
  
  }

  async function handleResend()
  {
    const token = await localforage.getItem('token');
    const baseURL = process.env.REACT_APP_BASE_URL
    axios.post(`${baseURL}/api/customer/mobile/resendCode`, {
      token: token,
    })
    .then(function(response) {
        const data = response.data;
        if (data.status) {
          setHavingError(false)
          setHavingSuccess(data.data)
        } else {
          setHavingSuccess(false)
          setHavingError(data.message)
        }
    })
    .catch(function(error) {
        console.error('Error:', error);
    });
  }

  async function handleChangeNumber() {
    setDisabledChange(true)
    const token = await localforage.getItem('token');
    const baseURL = process.env.REACT_APP_BASE_URL
    axios.post(`${baseURL}/api/customer/mobile/changeNumber`, {
      token: token,
      number: number
    })
    .then(function(response) {
        const data = response.data;
        if (data.status) {
          setDisabledChange(false)
          setHavingError(false)
          setHavingSuccess(data.data)
          setChangeMobileView(false)
          setMobileAfterChange(number)
        } else {
          setDisabledChange(false)
          setHavingSuccess(false)
          setHavingError(data.message)
        }
    })
    .catch(function(error) {
      setDisabledChange(false)
      setHavingError('Error:', error)
    });
  }
 
  return (
    <div className='bg-white mt-3 p-4 rounded w-verify'>
      {havingError &&
        <div id="error-message" className="error-message">
          {havingError}
        </div>
      }
      {havingSuccess &&
        <div id="" className="success-message">
          {havingSuccess}
        </div>
      }
      {disbaled &&
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      }
      {changeMobileView ? (
        <>
          <h1 className='text-center head-p top-head'>CHANGE MOBILE NUMBER</h1>
          <div className='form-group col-md-12 col-sm-12'>
          <label>Mobile Number <span className='text-danger'>*</span></label>
          <PhoneInput
            country={'au'}
            disableDropdown={true}
            countryCodeEditable={false}
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            containerStyle={{ width: '100%' }}
            inputStyle={{ paddingLeft: '50px', fontSize: '16px', width: '100%' }}
            onChange={(e) => setMobile(e)}
          />
          <button onClick={handleChangeNumber} className="btn btn-primary mt-3 w-100" disabled={disbaledChange}>Change</button>
          <button onClick={() => setChangeMobileView(false)} className="btn mt-3 w-100 cancel-btn">Cancel</button>

        </div>
        </>
      ) : (
        <>
        <h1 className='text-center head-p top-head'>MOBILE NUMBER VERIFICATION​</h1>
        <p className='f14 text-center text-dark fw-semibold'>A verification code has been sent to: +{mobile}</p> 
        <img src={lockImage} className='lockImage' />
  
        <div className='form-group'>
          <label className='my-2'>Verification Code <span className='text-danger'>*</span> </label>
          <input
            type='text'
            className='form-control'
            placeholder='Enter 6-digit code'
            onChange={(e) => {
              const input = e.target.value;
              const digits = input.replace(/\D/g, '');
              const formattedCode = digits
                .slice(0, 6)
                .replace(/(.{1})/g, '$1-')
                .slice(0, -1)
              setCode(formattedCode);
            }}
            value={code}
          />
        </div>
  
        <button onClick={handleCodeSubmit} className="btn btn-custom mt-3 w-100" disabled={disbaled}>Confirm</button>

        <div className='row my-3'>
        <button onClick={handleResend} className='btn text-blue font-btn col-6'>Resend Code</button>
        <button onClick={() => setChangeMobileView(true)} className='btn text-blue font-btn col-6 '>Change Mobile Number</button>
      </div>
        </>
      )}
    </div>
  )
}

export default Verify;
