import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../style/register.css';
import axios from 'axios';
import localforage from 'localforage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Register() {
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState('');
  const [subData, setSubData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [mobile,setMobile] = useState();
  const [email,setEmail] = useState();
  const [amount,setAmount] = useState();
  const [date,setDate] = useState(null);
  const [time,setTime] = useState();
  
  const [disabled,setDisabled] = useState(false);

  const [amountError,setAmountError] =  useState()
  const [emailError,setEmailError] =  useState()
  const [firstNameError,setFirstNameError] =  useState()
  const [lastNameError,setLastNameError] =  useState()
  const [suburbError,setSuburbError] =  useState()
  const [havingError,setHavingError] =  useState(false)

  const [isSelecting, setIsSelecting] = useState(false);
  const [showTimeSlots, setShowTimeSlots] = useState(false);

  function handleFormSubmit(){
    setDisabled(true)
    if(validateForm())
    {
      setDisabled(false)
      return;
    }

    let parts = inputValue.split('-');

    let address = parts[0].trim();
    let postcode = parts[1].trim();

    const apiKey = process.env.REACT_APP_API_KEY
    const baseURL = process.env.REACT_APP_BASE_URL

      axios.post(`${baseURL}/api/customer/register`, {
        first_name: firstName,
        last_name: lastName,
        telephone_number: mobile,
        email: email,
        choose_suburb: inputValue,
        loan_amount: amount,
        address: address,
        postal_code: postcode,
        referral: process.env.REACT_APP_REFERRAL,
        api_key: apiKey,
        date: date,
        time: time
      })
      .then(function(response) {
        console.log(response.data)
          if(response.data.status) {
            localforage.setItem('token', response.data.token)
            const propsToPass = { mobile: mobile};
            navigate('/verify', { state: propsToPass });

          } else {
            setDisabled(false)
            setHavingError(response.data.message)
          }
      })
      .catch(function(error) {
        setDisabled(false)
        setHavingError('There was an error!', error);
      });
  
  }


  function validateForm(){
    //check first name
      if(firstName.length < 1)
      {
        setFirstNameError('First name must have at least 2 letters')
        return true;
      }
      else{
        setFirstNameError(false)
      }

      //check last name
      if(lastName.length < 1)
      {
        setLastNameError('Last name must have at least 2 letters')
        return true;
      }
      else{
        setLastNameError(false)
      }

      //check Email
      const emailCheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(emailCheck.test(email))
      {
        setEmailError(false)
      }else{
        setEmailError('Invalid email format')
        return true;
      }

      //check amout
      if(amount < 200000)
      {
        setAmountError('Loan amount must be at least $200000')
        return true;
      }
      else{
        setAmountError(false)
      }

      //check suburb
      if(inputValue === "") {
        setSuburbError('Select at least one suburb')
        return true;
      }
      else{
        setSuburbError(false)
      }

      return false;
  }

  // Today's date
  const today = new Date();
  
  // Date two months from today
  const twoMonthsLater = new Date();
  twoMonthsLater.setMonth(today.getMonth() + 2);

  function handleTime(event)
  {
    setTime(event.target.id)
    setShowTimeSlots(false);
  }

  const handleTimeChange = () => {
    setShowTimeSlots(false); // Hide the dropdown when the input value changes
  };

  function handleSubClick(event)
  {
    setIsSelecting(true);
    setInputValue(event.target.id)
    setSubData([]);
  }



  useEffect(() => {
    if (isSelecting) {
      setIsSelecting(false);
      return;
    }

    if (inputValue.length >= 3) {
      const fetchSuburbs = async () => {
        setLoading(true);
        setError(null);
        try {
          const baseURL = process.env.REACT_APP_BASE_URL
          const response = await axios.get(`${baseURL}/api/loadsuburbs`, {
            params: {
              term: inputValue,
              q: inputValue,
              _type: 'query'
            }
          });
          const apiResult = response.data.map(item => `${item.text} - ${item.id}`);
          setSubData(apiResult);
        } catch (err) {
          setError('Failed to fetch suburbs');
          console.error('Error fetching suburbs:', err);
        } finally {
          setLoading(false);
        }
      };

      fetchSuburbs();
    } else {
      setSubData([]);
    }
  }, [inputValue]);

  const getSuburbs = (event) => {
    setInputValue(event.target.value);
  };


  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 8; hour < 19; hour++) {
      slots.push(formatTime(hour, 0));
      slots.push(formatTime(hour, 30));
    }
    slots.push(formatTime(19, 0));
    return slots;
  };


  const formatTime = (hour, minutes) => {
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHour}:${formattedMinutes} ${ampm}`;
  };

    const getFilteredTimeSlots = () => {
    const allSlots = generateTimeSlots();
    const now = new Date();
    
    if (date) {
      const selectedDate = new Date(date);
      
      // If today is selected
      if (selectedDate.toDateString() === now.toDateString()) {
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();

        return allSlots.filter(slot => {
          const [time, ampm] = slot.split(' ');
          let [slotHour, slotMinutes] = time.split(':').map(Number);

          // Convert 12-hour format back to 24-hour format for comparison
          if (ampm === 'PM' && slotHour !== 12) slotHour += 12;
          if (ampm === 'AM' && slotHour === 12) slotHour = 0;

          if (slotHour > currentHour) {
            return true;
          } else if (slotHour === currentHour && slotMinutes > currentMinutes) {
            return true;
          }
          return false;
        });
      }
    }

    // If not today or no date selected, return all slots
    return allSlots;
  };


  return (
    <div className='bg-white mt-3 mb-5 p-5 rounded w-60'>
      {havingError &&
        <div id="error-message" className="error-message">
          {havingError}
        </div>
      }
      {disabled &&
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      }
      <h1 className='text-center head-p top-head'>CHAT WITH A HOME LOAN EXPERT</h1>
      {/* <p className='f14 text-center text-dark fw-semibold'>Rates from 40+ lenders</p> */}
      <div className="row my-4 justify-content-center">
        <div className='form-group col-md-6 col-sm-12'>
          <label>First Name <span className='text-danger'>*</span></label>
          <input 
           type='text'
           placeholder=''
           name='first_name'
           className='form-control'
           onChange={(e) => {
            const value = e.target.value 
            if(value.length > 1)
            {
              setFirstName(value)
              setFirstNameError()
            }else{
              setFirstNameError('First name must have at least 2 letters')
            }
           }}
          />
          <small className='text-danger'>{firstNameError}</small>
        </div>
        <div className='form-group col-md-6 col-sm-12 mobile-spacing'>
          <label>Last Name <span className='text-danger'>*</span></label>
          <input 
           type='text'
           placeholder=''
           name='last_name'
           className='form-control'
           onChange={(e) => 
           {
            const value = e.target.value 
            if(value.length > 1)
            {
              setLastName(value)
              setLastNameError()
            }else{
              setLastNameError('Last name must have at least 2 letters')
            }
           }}
          />
          <small className='text-danger'>{lastNameError}</small>
        </div>
      </div>

      <div className="row mb-4 justify-content-center ">
        <div className='form-group col-md-6 col-sm-12'>
          <label>Mobile Number <span className='text-danger'>*</span></label>
          <PhoneInput
            country={'au'}
            disableDropdown={true}
            countryCodeEditable={false}
            autoFormat={false}
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: false,
            }}
            value={mobile}
            containerStyle={{ width: '100%' }}
            inputStyle={{ paddingLeft: '50px', fontSize: '16px', width: '100%' }}
            onChange={(e) => setMobile(e)}
          />
          <small>A verification code will be sent to you.</small>
        </div>
        <div className='form-group col-md-6 col-sm-12 mobile-spacing'>
          <label>Email <span className='text-danger'>*</span></label>
          <input 
           type='email'
           placeholder=''
           name='email'
           className='form-control'
           onChange={(e) => {
            const value = e.target.value
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(emailRegex.test(value))
            {
              setEmail(e.target.value)
              setEmailError(false)
            }else{
              setEmailError('Invalid email format')
            } 
           }}
          />
          <small className='text-danger'>{emailError}</small>
        </div>
      </div>

      <div className="row mb-4 justify-content-center ">
        <div className='form-group col-md-6 col-sm-12'>
          <label>Suburb <span className='text-danger'>*</span></label>
            <input
              type="text"
              value={inputValue}
              onChange={getSuburbs}
              placeholder=""
              className='form-control'
            />
            <small className='text-danger'>{suburbError}</small>
            {loading && <div className='text-dark'>Loading...</div>}
            {error && <div>{error}</div>}
            {subData.length > 0 && (
              <div className="dropdown-menu-custom">
                {subData.map((item, index) => (
                    <p onClick={handleSubClick} id={item} className='pointer text-dark dropdown-item' key={index}>{item}</p>
                ))}
            </div>
            )}
        </div>
        <div className='form-group col-md-6 col-sm-12 mobile-spacing'>
          <label>Loan Amount <span className='text-danger'>*</span></label>
          <div className="amount-input">
            <span className="dollar-sign">$</span>
            <input 
            type='text'
            placeholder=''
            className='form-control loan-field'
            onChange={(e) => {
              if(e.target.value < 200000)
              {
                setAmountError('Loan amount must be at least $200000')
              }else{
                setAmount(e.target.value)
                setAmountError(false)
              }
            }}
            />
          </div>
          <small className='text-danger'>{amountError}</small>
        </div>
      </div>
      <div className="row mb-4 justify-content-center ">
        <div className='form-group col-md-6 col-sm-12'>
          <label>Preferred Call Date <span className='text-danger'>*</span></label>
           <DatePicker
            selected={date}
            onChange={(e) => {
              setTime('')
              setDate(e)
            }}
            minDate={today}
            maxDate={twoMonthsLater}
            className="form-control w-100"
            dateFormat="dd-MM-yyyy"
            style={{ boxSizing: 'border-box' }}
           />
        </div>
        <div className='form-group col-md-6 col-sm-12 mobile-spacing'>
            <label>Preferred Call Time <span className='text-danger'>*</span></label>
            <input
              type="text"
              className='form-control'
              value={time}
              onClick={() => setShowTimeSlots(!showTimeSlots)}
              onChange={handleTimeChange}
            />
            {showTimeSlots && (
              <div className="dropdown-menu-custom remove-overflow">
                <div className='row'>
                  {getFilteredTimeSlots().map((slot, index) => (
                    <div className='col-md-6 col-sm-6 col-lg-6'>
                      <div className='container'>
                        <p onClick={handleTime} id={slot} className='rounded text-center bg-slot dropdown-item' key={index}>{slot}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
      </div>
      <div className="my-4">
        <button type="button" onClick={handleFormSubmit} className="btn btn-custom float-end" disabled={disabled}>
          Schedule <FontAwesomeIcon icon={faArrowRight} className="arrow" />
        </button>
      </div>
    </div>
  );
}

export default Register;
