import React, { useState,useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../style/confirmation.css';
import moment from 'moment';

function Confirmation() {
  const location = useLocation();

  const { 
    name,
    date,
    time,
    email,
    phone,
    image,
    legalName
   } = location.state || {};

   
   const dateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm A');

    const formattedDate = dateTime.format('dddd, Do MMMM');
    const formattedTime = dateTime.format('h:mm A');

  return (
    <div className='bg-white mt-3 p-4 rounded w-confirmation'>
      <h3 className='text-dark text-center f-blod'>Your Appointment is Confirmed!</h3>

      <div className="row">
        <div className="col-10 offset-2">
            <div className="row mt-3">
                <div className="col-3">
                  <img src={image } className="img-fluid broker-img d-block mx-auto"
                      alt="" />
                </div>
                <div className="col-8 ">
                    <b className='text-dark'>{ name }</b>
                    <br />
                    <span>{ phone }</span>
                    <br />
                    <span>{ email }</span>
                </div>
            </div>
        </div>
    </div>

    <hr className='text-dark' />
    <p className="text-center mt-4">
        <span  className="call_success_date_time rounded">
        {formattedDate} at {formattedTime}
        </span>
    </p>
    <center><p className="text-dark font-custom mt-3">Feel free to call or email the broker to <br />
          reschedule the date and time of the call</p></center>
    </div>
  );
}

export default Confirmation;
