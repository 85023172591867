import logo from './images/logo.png';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Register from './components/Register';
import Verify from './components/Verify';
import Confirmation from './components/Confirmation';

function App() {
  return (
    <Router>
      <div className="App app-bg">
        <header className="App-header">
          <img src={logo} className="logo" alt="logo" />
        </header>
        <Routes>
          <Route path="/" element={<Register />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/confirmation" element={<Confirmation />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
